
import PrivacyPolicyHandler from "../../../utils/privacy-policy-handler";
import { RESIGNUP_FORM_KEY } from "./re-signup-field-config";

// Calculate the next year based on the current year
const nextYear = new Date().getFullYear() + 1;


const ReSignupFields = [
    {
        key: RESIGNUP_FORM_KEY.documentoId,
        label: 'DNI del alumno',
        type: 'TextField',
        required: true,
        hidden: false,
        validation: {
            state: true,
            type: 'dni'
        },
        disabled: false,
        description: 'Documento Nacional de Identidad.',
    },
    {
        key: RESIGNUP_FORM_KEY.periodoLectivo,
        label: 'Periodo lectivo',
        type: 'OptionField',
        required: true,
        multipleLines: true,
        hidden: false,
        disabled: false,
        description: 'Periodo lectivo al que se re-inscribe el alumno',
        default: 'No',
        choice_values: [
            {
                label: nextYear.toString(),
                value: nextYear.toString(),
            },
        ],
    },
    {
        key: RESIGNUP_FORM_KEY.nombreCompletoTutor,
        label: 'Nombre del Tutor',
        type: 'TextField',
        required: true,
        hidden: false,
        validation: {
            state: true,
            type: 'min'
        },
        disabled: false,
        description: 'Introduzca su numbre completo.',
    },
    {
        key: RESIGNUP_FORM_KEY.emailTutor,
        label: 'Email del tutor',
        type: 'TextField',
        required: true,
        validation: {
            state: true,
            type: 'email'
        },
        hidden: false,
        disabled: false,
        description: 'email del tutor.',
    },
    {
        key: RESIGNUP_FORM_KEY.telTutor,
        label: 'Telefono',
        type: 'TextField',
        required: true,
        hidden: false,
        description: '',
        validation: {
            state: true,
            type: 'telephone'
        },
    },
    {
        key: RESIGNUP_FORM_KEY.okPrivacy,
        label: 'Privacidad',
        type: 'CheckField',
        required: true,
        hidden: false,
        disabled: false,
        description: () => <PrivacyPolicyHandler />
    },
];

export default ReSignupFields;