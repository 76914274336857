
import * as React from 'react';
import ContactForm from './forms/contact';

const Footer: React.FC = () => {
    return (
        <footer>
            <div className="container">
                <div className="modal fade hide" id="mymodal" role="alert">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Rayces - Centro de Rehabilitacion e Inclusion Escolar</h3>
                            </div>
                            <div className="modal-body">
                                <h4>Muchas gracias por su mensaje.</h4>
                            </div>
                            <div className="modal-footer">

                                <button className="btn btn-md btn-default" type="button" data-dismiss="modal">Cerrar</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div id="contact-left">
                                <h3>RAYCES</h3>
                                <p>Con <strong>conocimiento</strong> y <strong>compromiso</strong>, generamos espacios de <strong>calidez</strong> y <strong>honestidad</strong>, brindando respuestas para asegurar que <strong>tu hij@ va a estar bien.</strong></p>
                                <div id="contact-info">
                                    <address>
                                    <strong>Casa Central:</strong><br/>
                                    <p>12 de Octubre 832, 4400 Salta, Argentina</p>
                                </address>
                                    <div id="phone-fax-email">
                                        <p>
                                            <strong>Telefono:</strong> <span>(54387) 422-9166</span> <br/>
                                            <strong>Email:</strong> <span>info@rayces.com</span> <br/>
                                        </p>
                                    </div>
                                </div>
                                <ul className="social-list">
                                    <li><a href="https://www.facebook.com/mirayces/" className="social-icon icon-white"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="https://instagram.com/raycessalta" className="social-icon icon-white"><i className="fa fa-instagram"></i></a></li>
                                    <li><a href="https://www.youtube.com/channel/UCwb6tIfzC_9K-POOX9DA4NQ" className="social-icon icon-white"><i className="fa fa-youtube-play"></i></a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="wow flipInY mfp-hide contacto-1 event-popup" id="event-1">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <div className="event-content">
                                        <div className="event-header">
                                            <h3>RAYCES<br/>Centro de rehabilitacion e Inclusion Escolar</h3>
                                        </div>
                                        <div className="event-body">
                                            <div className="contacto-ok alert alert-success" role="alert" hidden></div>
                                            <div className="contacto-die alert alert-danger" role="alert" hidden></div>
                                            <div className="contacto-warning alert alert-warning" role="alert" hidden></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <ContactForm />
                        </div>

                    </div>
                </div>
            </div>
            <div id="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div id="footer-copyrights">
                                <p>Copyright &copy; {new Date().getFullYear()}/{new Date().getFullYear()+1} Todos los derechos reservados por Rayces.</p>
                            </div>
                        </div>
                        <div className="col-md-6 hidden-sm hidden-xs">
                            <div id="footer-menu">
                                <ul>
                                    <li><a className="smooth-scroll" href="#home">Inicio</a>/</li>
                                    <li><a className="smooth-scroll" href="#services">Servicios</a>/</li>
                                    <li><a className="smooth-scroll" href="#about">Rayces</a>/</li>
                                    <li><a className="smooth-scroll" href="#work">Pasión</a>/</li>
                                    <li><a className="smooth-scroll" href="#team">Equipo</a>/</li>
                                    <li><a className="smooth-scroll" href="#testimonials">Testimonio</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <a href="#home" id="back-to-top" className="btn btn-sm btn-blue btn-back-to-top smooth-scroll hidden-sm hidden-xs" title="home" role="button">
            <i className="fa fa-angle-up"></i></a>
        </footer>
    )
}

export default Footer;